<div style="padding: 10px;">
    <div class="flex">
        <div class="right15" *ngIf="_data?.shipment?.deliveryInfos[0]?.refNums">
            Pickup Ref Numbers: <code *ngFor="let f of _data.shipment.deliveryInfos[0]?.refNums">{{ f }}</code>
        </div>
        <div *ngIf="_data?.shipment?.deliveryInfos[1]?.refNums">
            Dropoff Ref Numbers: <code *ngFor="let f of _data.shipment.deliveryInfos[1]?.refNums">{{ f }}</code>
        </div>
    </div>

<div class="bottom5 font-bold">Items</div>
<nz-table [nzData]="quantityItems" nzSize="small" [nzShowPagination]="false" nzBordered>
    <thead>
        <tr>
            <th nzWidth="100px">Code</th>
            <th nzWidth="200px">Name</th>
            <th nzWidth="200px">Dimension</th>
            <th nzWidth="160px">Weight</th>
            <th>Tasks</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let shipmentItem of items">
            <tr *ngFor="let item of shipmentItem.quantityItems">
                <ng-container *ngTemplateOutlet="itemRow; context: {$implicit: item}"></ng-container>            
            </tr>
        </ng-container>
    </tbody>
</nz-table>        
</div>
<ng-template #itemRow let-item>
    <td>
        <code *ngIf="item.barcodes?.length">{{barcodeText(item.barcodes)}}</code>
        <code *ngIf="!item.barcodes?.length" class="not-identified">N/A</code>    
    </td>
    <td>{{ item.name }}</td>
    <td>
        {{item.length || '-'}} x {{ item.width || '-'}} x {{item.height  || '-'}} {{ item.sizeUnit }}
    </td>
    <td>
        <span class="font-medium" *ngIf="item.actualWeight">{{ item.actualWeight }} {{item.actualUnit || item.weightUnit}}</span>
        <span *ngIf="!item.actualWeight && item.weightPerUnit" class="not-identified">{{ item.weightPerUnit.toFixed(0) }} {{ item.weightUnit}}</span>
    </td>
    <td>
        <warehouse-task-chip [task]="t" *ngFor="let t of item.warehouseTasks"></warehouse-task-chip>
    </td>
    <td>
        <ng-container *ngFor="let task of item.warehouseTasks">
            <ng-container *ngIf="task.type == 'uploadProductPhoto' && task.data?.images">
                <ng-container *ngFor="let img of task.data.images">
                    <img [src]="img.fileUrl" class="thumbnail">
                </ng-container>
            </ng-container>
        </ng-container>
    </td>
</ng-template>