import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { DataorchService } from "@services/dataorch.service";
import _ from "underscore";
import { Utils as ServiceUtils, Utils } from "@services/utils";
import { NewLinehaulRouteForm } from "../new-route-form";
import { BizUtil } from "@services/biz";
import { LinehaulRollRouteForm } from "../roll-form";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { WarpId } from "@wearewarp/universal-libs";
import { JobNotesForm } from "./job-notes-form";
import { EventsContainer } from "@app/admin/components/events";
import { getFeatureFlags } from "@services/feature-flag.service";
import { JobRefNumsForm } from "./job-ref-nums-form";
import { LinehaulUpdatePickupTimeForm } from "./update-pickup-time-form";
import { PlanningService } from "@services/planning.service";
import { AttachedFile } from "@wearewarp/types/data-model";

@Component({
    selector: '[linehaul-lane-inventory]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class LinehaulLaneInventory extends BaseComponent {
    dataorch: DataorchService
    planning: PlanningService
    _data: {
        id?: string,
        lane?: any,
        pending?: any
    } = {}
    loading: boolean = false
    shipments: any[] = []

    readyForOutbound: any = null
    arrivedAtWarehouse: any = null
    arrivingAtWarehouse: any = null
    scheduledInbound: any = null
    pending: any = null
    scheduledForOutbound: any = null
    departed: any = null
    completed: any = null

    pendingStatuses: Set<string> = new Set(['pending', 'readyForPickup'])
    scheduledInboundStatuses: Set<string> = new Set(['scheduledToPickup'])
    SAKS_ID = '01H22NK3KK5KJHR8BX7AB9E4W1'

    constructor() {
        super()
        this.dataorch = new DataorchService(this.api)
        this.planning = new PlanningService(this.api)
    }

    ngOnInit(): void {
        getFeatureFlags().isFlagSetNonBlocking('SHOW_EVENT').subscribe((res) => {
            if (res) {
                this.showEvents = true;
            }      
        })
    }

    @Input() set data(v: { id?: string, pending?: any, lane?: any }) {
        this._data = v
        if (!v.pending && v.id) {
            this.loadPendingShipments(v.id)
        }
        if (!v.lane && v.id) {
            // load lane
        }
        if (v.pending) {
            this.processShipments()
        }
        this.loadCompletedJobs(0)
    }

    processShipments() {
        const clients: any = {}
        const items: any = {}
        const inboundWarehouseJobs: any = {}
        const inboundWarehouseTasks: any = {}
        const itemWarehouseTasks: any = {}
        const inboundLegs: any = {}
        const jobs: any = {}

        if (this._data?.pending?.clients?.length)
            for (let c of this._data.pending.clients) {
                clients[c.id] = c
            }
        if (this._data?.pending?.items?.length) {
            for (let i of this._data.pending.items) {
                items[i.id] = i
            }
        }
        if (this._data?.pending?.inboundLegs?.length) {
            for (let i of this._data.pending.inboundLegs) {
                inboundLegs[i.id] = i
            }
        }
        if (this._data?.pending?.warehouseJobs?.length) {
            for (let x of this._data.pending.warehouseJobs) {
                if (x.level === 'shipment') {
                    const l = inboundWarehouseJobs[x.outboundLegId] || []
                    l.push(x)
                    inboundWarehouseJobs[x.outboundLegId] = l
                }
            }
        }
        if (this._data?.pending?.warehouseTasks?.length) {
            for (let x of this._data.pending.warehouseTasks) {
                if (x.level == 'item') {
                    const l = itemWarehouseTasks[x.itemId] || []
                    l.push(x)
                    itemWarehouseTasks[x.itemId] = l
                } else if (x.level == 'shipment') {
                    const l = inboundWarehouseTasks[x.outboundLegId] || []
                    l.push(x)
                    inboundWarehouseTasks[x.outboundLegId] = l
                }
            }
        }
        if (this._data?.pending?.items?.length) {
            for (let item of this._data.pending.items) {
                item.warehouseTasks = itemWarehouseTasks[item.id] || []
                if (item.quantityItems) {
                    for (let q of item.quantityItems) {
                        q.warehouseTasks = _.sortBy(itemWarehouseTasks[q.id] || [], 'type')
                    }
                }
            }
        }

        if (this._data?.pending?.jobs?.length)
            for (let job of this._data.pending.jobs) {
                jobs[job.id] = job
                if (job.tags && job.tags.indexOf('HOLD') >= 0) {
                    job.onHold = true
                } else {
                    job.onHold = false
                }
                job.bol = {
                    _id: job.bolFileId,
                    type: 'application/pdf',
                    title: 'BOL',
                    name: 'BOL-' + job.code,
                    url: Const.APIURI_JOBS + `/${job.id}/bol`
                }
                job.loadTender = {
                    _id: job.assignedCarrier?.loadTenderFileId,
                    type: 'application/pdf',
                    name: 'Load-Tender-' + job.code,
                    title: 'Load Tender',
                    url: Const.APIURI_JOBS + `/${job.id}/load_tender`
                }

                if (!job.status || job.status == 'created') {
                    if (job.assignedCarrier) {
                        job.status = 'assigned'
                    }
                }

            }


        const shipments = this._data?.pending?.shipments || []
        for (let s of shipments) {
            s.client = clients[s.clientId]
            s.items = (s.itemIds || []).map(it => items[it]).filter(it => it)
            s.workload = s.items.length ? _.reduce(s.items.map(it => it.qty), (a, b) => a + b) : 0
            s.inboundJobs = inboundWarehouseJobs[s.id] || []
            s.warehouseTasks = inboundWarehouseTasks[s.id] || []
            const inboundLegIds = _.uniq(s.inboundJobs.map(it => it.inboundLegId).filter(it => it))
            s.inboundLegs = inboundLegIds.map(it => inboundLegs[it]).filter(it => it)
            if (s.inboundLegs.length) {
                const dates = _.sortBy(s.inboundLegs.map(it => this.getPickupDate(it)).filter(it => it))
                s.scheduledInbound = dates[0]

                const completedDates = _.sortBy(s.inboundLegs.map(it => this.getCompleteDate(it)).filter(it => it))
                s.completedInbound = completedDates[0]
            }
        }

        const readyForOutbound = shipments.filter(it => it.inboundStatus === 'readyForOutbound')
        const arrivedAtWarehouse = shipments.filter(it => it.inboundStatus === 'arrivedAtWarehouse')
        const arrivingAtWarehouse = shipments.filter(it => it.inboundStatus === 'pickedUp')
        const pending = shipments.filter(it => this.pendingStatuses.has(it.inboundStatus))
        const scheduledInbound = shipments.filter(it => this.scheduledInboundStatuses.has(it.inboundStatus))
        const scheduledForOutbound = shipments.filter(it => it.inboundStatus === 'scheduledForOutbound')
        const departed = shipments.filter(it => it.inboundStatus === 'departed')

        const buildStats = (list) => Object.assign({}, {
            shipments: list,
            shipmentCount: list.length,
            // show: list.length > 0,
            palletCount: list.length ? _.reduce(list.filter(it => it.metadata?.zero_load !== 'true').map(it => it.workload), (a, b) => a + b) : 0
        })

        let processedIds: any[] = []

        const departedJobIds = departed.map(it => it.lastJobId).filter(it => it)
        const scheduledForOutboundJobIds = shipments.map(it => it.lastJobId)
            .filter(it => it).filter(it => departedJobIds.indexOf(it) < 0)

        const departedJobs = _.uniq(departedJobIds).map(it => jobs[it]).filter(it => it)
        for (let job of departedJobs) {
            job.saks =  job.clientIds?.indexOf(this.SAKS_ID) >= 0
            const jobShipments = shipments.filter(x => x.lastJobId == job.id)
            processedIds = processedIds.concat(jobShipments.map(it => it.id))
            Object.assign(job, buildStats(jobShipments))
        }

        const scheduledForOutboundJobs = (this._data?.pending?.jobs || []).filter(it => departedJobIds.indexOf(it.id) < 0)
        for (let job of scheduledForOutboundJobs) {
            job.allChecked = false
            job.saks =  job.clientIds?.indexOf(this.SAKS_ID) >= 0
            const jobShipments = shipments.filter(x => x.lastJobId == job.id)
            processedIds = processedIds.concat(jobShipments.map(it => it.id))
            Object.assign(job, buildStats(jobShipments))
        }

        this.scheduledForOutbound = {
            jobs: _.sortBy(scheduledForOutboundJobs, it => it.pickDt?.time ?? 0),
            shipmentCount: scheduledForOutbound.length,
            show: this.scheduledForOutbound?.show ?? scheduledForOutboundJobs.length > 0
        }
        this.departed = {
            jobs: departedJobs,
            shipmentCount: departed.length,
            show: this.departed?.show ?? departed.length > 0
        }

        this.readyForOutbound = Object.assign(
            { allChecked: false },
            this.readyForOutbound,
            buildStats(readyForOutbound.filter(it => processedIds.indexOf(it.id) < 0))
        )
        this.pending = Object.assign(
            { allChecked: false },
            this.pending,
            buildStats(pending.filter(it => processedIds.indexOf(it.id) < 0))
        )
        this.scheduledInbound = Object.assign(
            { allChecked: false },
            this.scheduledInbound,
            buildStats(scheduledInbound.filter(it => processedIds.indexOf(it.id) < 0))
        )
        this.arrivedAtWarehouse = Object.assign(
            { allChecked: false },
            this.arrivedAtWarehouse,
            buildStats(arrivedAtWarehouse.filter(it => processedIds.indexOf(it.id) < 0))
        )
        this.arrivingAtWarehouse = Object.assign(
            { allChecked: false },
            this.arrivingAtWarehouse,
            buildStats(arrivingAtWarehouse.filter(it => processedIds.indexOf(it.id) < 0))
        )
    }

    getPickupDate(shipment) {
        const pickup = BizUtil.getPickInfo(shipment)
        if (pickup.appointmentInfo?.from) {
            return pickup.appointmentInfo?.from
        }
        if (pickup.windows?.length) {
            const w = pickup.windows[0]
            if (w.from) return w.from
        }
        return null
    }

    getCompleteDate(shipment) {
        const log = shipment.statusChangeLog?.complete
        return log?.changeWhen ?? log?.when
    }

    onRefreshBtn() {
        this.loadPendingShipments(this._data.id)
    }

    loadPendingShipments(id) {
        this.loading = true
        this.planning.listLinehaulLanePendingShipment(id).subscribe(r => {
            this.loading = false
            this._data.pending = r
            this.processShipments()
        })
    }

    openJob(job) {
        this.router.navigate([this.routeAdminDispatchList, job.id])
    }

    onItemChecked(listContainer, id, $event) {
        if (!listContainer.setOfCheckedIds) {
            listContainer.setOfCheckedIds = new Set()
        }
        if (listContainer.setOfCheckedIds.has(id)) {
            listContainer.setOfCheckedIds.delete(id)
        } else {
            listContainer.setOfCheckedIds.add(id)
        }
        if (listContainer.setOfCheckedIds.size == 0) {
            listContainer.allChecked = false
        } else if (listContainer.setOfCheckedIds.size == listContainer.shipments.length) {
            listContainer.allChecked = true
        }
        const selected = listContainer.shipments.filter(it => listContainer.setOfCheckedIds.has(it.id))
        listContainer.selectedPalletCount = selected.length ? _.reduce(selected.filter(it => it.metadata?.zero_load !== 'true').map(it => it.workload), (a, b) => a + b) : 0
    }

    onAllChecked(listContainer, $event) {
        if (!listContainer.setOfCheckedIds) {
            listContainer.setOfCheckedIds = new Set()
        }

        if (listContainer.allChecked) {
            listContainer.setOfCheckedIds.clear()
        } else {
            for (let s of listContainer.shipments) {
                listContainer.setOfCheckedIds.add(s.id)
            }
        }
        listContainer.allChecked = !listContainer.allChecked

        const selected = listContainer.shipments.filter(it => listContainer.setOfCheckedIds.has(it.id))
        listContainer.selectedPalletCount = selected.length ? _.reduce(selected.filter(it => it.metadata?.zero_load !== 'true').map(it => it.workload), (a, b) => a + b) : 0
    }

    onOpenShipment(event, listContainer, shipment) {
        if (!listContainer.expandedSet) {
            listContainer.expandedSet = new Set()
        }
        if (listContainer.expandedSet.has(shipment.id)) {
            listContainer.expandedSet.delete(shipment.id)
        } else {
            listContainer.expandedSet.add(shipment.id)
        }
    }

    onSelectJobForShipmentsBtn(shipmentIds, job) {
        const shipments = this._data.pending.shipments.filter(it => shipmentIds.has(it.id))
        const ids = shipments.map(it => it.warpId.toString()).join(", ")

        this.modalService.create({
            nzContent: `Are you sure you want to add ${shipments.length} shipments: ${ids} to route ${job.code}?`,
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: 'Confirm',
            nzOnOk: () => {
                this.planning.scheduleShipment(this._data.id, job.id, [...shipmentIds], null, null, null).subscribe(res => {
                    // refresh bol
                    this.api.POST(Const.APIURI_JOBS + `/${job.id}/bol`).subscribe((x) => {
                        console.log('Refreshed BOL')
                    })
                    this.api.POST(Const.APIURI_JOBS + `/${job.id}/load_tender`).subscribe((x) => {
                        console.log('Refreshed Load Tender')
                    })
                    this.onRefreshBtn()
                })
            },
            nzCancelText: 'Cancel'
        });
    }

    onCreateNewRouteBtn(shipmentIds) {
        const shipments = shipmentIds ? this._data.pending.shipments.filter(it => shipmentIds.has(it.id)) : []

        const modal = this.modalService.create({
            nzContent: NewLinehaulRouteForm,
            nzComponentParams: {
                shipments: shipments,
                lane: this._data.lane,
                onClose: () => modal.destroy(),
                onOk: () => {
                    modal.destroy()
                    this.onRefreshBtn()
                }
            },
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: null,
            nzFooter: null,
            nzCancelText: null
        });

    }

    copyIds(shipmentIds) {
        const shipments = this._data.pending.shipments.filter(it => shipmentIds.has(it.id))
        const ids = shipments.map(it => it.warpId.toString()).join(", ")

        ServiceUtils.copyTextToClipboard(ids, (e) => {
            if (e) {
                this.showErr("Cannot copy to clipboard");
            } else {
                this.showSuccess(
                    `Copied to the clipboard ${ids}`
                );
            }
        })
    }

    onRemoveJobBtn(job) {
        this.modalService.create({
            nzContent: `Are you sure you want to remove this route: ${job.code}?`,
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: 'Confirm',
            nzOnOk: () => {
                this.dataorch.removeJob(job.id).subscribe(res => {
                    this.onRefreshBtn()
                })
            },
            nzCancelText: 'Cancel'
        });
    }

    onHoldJobBtn(job) {
        this.modalService.create({
            nzContent: `Are you sure you want to put on hold this route: ${job.code}?`,
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: 'Confirm',
            nzOnOk: () => {
                this.dataorch.putOnHold(job.id).subscribe(res => {
                    if (res) {
                        job.onHold = true
                    }
                })
            },
            nzCancelText: 'Cancel'
        });
    }

    onUnholdJobBtn(job) {
        this.modalService.create({
            nzContent: `Are you sure you want to un-hold this route: ${job.code}?`,
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: 'Confirm',
            nzOnOk: () => {
                this.dataorch.unHold(job.id).subscribe(res => {
                    if (res) {
                        job.onHold = false
                    }
                })
            },
            nzCancelText: 'Cancel'
        });
    }

    onRollRouteBtn(job) {
        const modal = this.modalService.create({
            nzContent: LinehaulRollRouteForm,
            nzComponentParams: {
                job: job,
                onClose: () => modal.destroy(),
                onOk: () => {
                    modal.destroy()
                    this.onRefreshBtn()
                }
            },
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: null,
            nzFooter: null,
            nzCancelText: null
        });
    }

    onChangePickupBtn(job) {
        const modal = this.modalService.create({
            nzContent: LinehaulUpdatePickupTimeForm,
            nzComponentParams: {
                job: job,
                onClose: () => modal.destroy(),
                onOk: () => {
                    modal.destroy()
                    this.onRefreshBtn()
                }
            },
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: null,
            nzFooter: null,
            nzCancelText: null
        });
    }

    getJobWarehouseJobIds(job) {
        const selected = job.shipments.filter(it => job.setOfCheckedIds?.has(it.id))
        const shipments = selected.length ? selected : job.shipments
        const inboundJobs = _.flatten(shipments.map(it => it.inboundJobs || []))
        return inboundJobs.map(it => it.id)
    }

    loadingBol = false
    onDownloadJobShipmentBOLs(job) {
        // list all tasks
        const warehouseJobIds = this.getJobWarehouseJobIds(job)
        this.loadingBol = true
        this.api.POST(Const.APIURI_WAREHOUSE_DOWNLOAD_BOL, { warehouseJobIds }).subscribe(res => {
            job.attachedFile = res.data;
            this.downloadAttachedFile(job.attachedFile);
            this.loadingBol = false
        }, err => {
            this.loadingBol = false
            this.showErr(err)
        })
    }

    isExporting = false;
    onExportJobManifest(job) {
        const warehouseJobIds = this.getJobWarehouseJobIds(job)
        this.isExporting = true;
        const params = { warehouseJobIds: warehouseJobIds, isDownload: true };
        const warehouseId = job.shipments[0]['deliveryInfos'][0].warehouseId
        const ops = { customHeaders: { warehouseid: warehouseId } };
        this.api.
            postExport(Const.APIURI_WAREHOUSE_EXPORT_JOB, params, ops)
            .subscribe(
                resp => {
                    ApiService.handleDownloadResponse(resp);
                    this.isExporting = false;
                }, err => {
                    this.showErr(err);
                    this.isExporting = false;
                }
            );
    }

    onDownloadShipmentBOL(shipment) {
        const bolInfo: any = {
            _id: shipment.bolFileId,
            type: 'application/pdf',
            name: `BOL-${WarpId.showShipment(shipment)}`
        };
        this.downloadAttachedFile(bolInfo)
    }

    onDownloadFile(file) {
        if (file._id)
            this.downloadAttachedFile(file)
        else
            this.onRefreshFile(file)
    }

    onRefreshFile(file) {
        file.refreshing = true
        this.api.POST(file.url).subscribe(
            res => {
                file.refreshing = false
                file._id = res.data.id
                this.onDownloadFile(file)
            }, err => {
                file.refreshing = false
                this.showErr(err)
            }
        )
    }

    onRefreshJobLoadTender(job) {
        job.refreshingLoadTender = true
    }

    onExportShipmentManifest(listContainer) {
        const shipments = listContainer.shipments.filter(it => listContainer.setOfCheckedIds.has(it.id))
        const inboundJobs = _.flatten(shipments.map(it => it.inboundJobs || []))
        if (!inboundJobs?.length) return
        const warehouseJobIds = inboundJobs.map(it => it.id)
        listContainer.isExportingManifest = true;
        const params = { warehouseJobIds: warehouseJobIds, isDownload: true };
        const warehouseId = inboundJobs[0].warehouseId
        const ops = { customHeaders: { warehouseid: warehouseId } };
        this.api.
            postExport(Const.APIURI_WAREHOUSE_EXPORT_JOB, params, ops)
            .subscribe(
                resp => {
                    ApiService.handleDownloadResponse(resp);
                    listContainer.isExportingManifest = false;
                }, err => {
                    this.showErr(err);
                    listContainer.isExportingManifest = false;
                }
            );
    }

    onDownloadShipmentsBOLs(listContainer) {
        const shipments = listContainer.shipments.filter(it => listContainer.setOfCheckedIds.has(it.id))
        const inboundJobs = _.flatten(shipments.map(it => it.inboundJobs || []))
        if (!inboundJobs?.length) return

        // list all tasks
        const warehouseJobIds = inboundJobs.map(it => it.id)
        listContainer.loadingBol = true
        this.api.POST(Const.APIURI_WAREHOUSE_DOWNLOAD_BOL, { warehouseJobIds }).subscribe(res => {
            this.downloadAttachedFile(res.data);
            listContainer.loadingBol = false
        }, err => {
            listContainer.loadingBol = false
            this.showErr(err)
        })
    }

    async downloadSaksManifest(job) {
        if (!job?.id) {
            return this.showWarning('', 'jobId is required.')
        }
        let url = `${Const.APIV2('reports')}/saks-manifest`;
        const name = job.codd + '_' + Date.now().toString();
        // TODO: move to config / metadata
        job.isExportingSaksManifest = true
        this.api.POST(url, { jobId: job.id, clientId: this.SAKS_ID }, { responseType: 'arraybuffer' }).subscribe(
            (resp) => {
                let blob = new Blob([resp], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                let url = URL.createObjectURL(blob);
                let fileName = `${name}.xlsx`;
                Utils.downloadFile(url, fileName);
                job.isExportingSaksManifest = false
            },
            (err) => {
                this.showErr(err);
                job.isExportingSaksManifest = false
            }
        );
    }

    onEditJobNotes(job) {
        const modafRef = this.modalService.create({
            nzContent: JobNotesForm,
            nzTitle: `Update Notes for route ${job.code}`,
            nzComponentParams: {
                job: job,
                onOk: () => {
                    this.api.POST(Const.APIURI_JOBS + `/${job.id}/bol`).subscribe((res) => {
                        job.bol = res.data
                        job.bol.title = 'BOL'
                        job.bol.url = Const.APIURI_JOBS + `/${job.id}/bol`        
                    })
                    modafRef.destroy()
                },
                onClose: () => {
                    modafRef.destroy()
                }
            },
            nzFooter: null
        })
    }

    onEditJobRefNums(job) {
        const modafRef = this.modalService.create({
            nzContent: JobRefNumsForm,
            nzTitle: `Update Ref# for route ${job.code}`,
            nzComponentParams: {
                job: job,
                onOk: () => {
                    this.api.POST(Const.APIURI_JOBS + `/${job.id}/bol`).subscribe((res) => {
                        job.bol = res.data
                        job.bol.title = 'BOL'
                        job.bol.url = Const.APIURI_JOBS + `/${job.id}/bol`        
                    })
                    modafRef.destroy()
                },
                onClose: () => {
                    modafRef.destroy()
                }
            },
            nzFooter: null
        })
    }

    showEvents: boolean = false
    showEventHistory(type, id) {
        this.drawerService.create({
            nzContent: EventsContainer,
            nzClosable: false,
            nzContentParams: {
                type,
                id
            }
        });
    }
    
    loadingCompletedJobs = false
    loadCompletedJobs(skip) {
        this.loadingCompletedJobs = true
        this.planning.listLinehaulLaneCompletedJobs(this._data.id, skip).subscribe((res) => {
            this.loadingCompletedJobs = false
            for (let job of res) {
                job.saks =  job.clientIds?.indexOf(this.SAKS_ID) >= 0
                if (job.bolFileId)
                job.bol = {
                    _id: job.bolFileId,
                    type: 'application/pdf',
                    title: 'BOL',
                    name: 'BOL-' + job.code,
                    url: Const.APIURI_JOBS + `/${job.id}/bol`
                }
                if (job.assignedCarrier?.loadTenderFileId)
                job.loadTender = {
                    _id: job.assignedCarrier?.loadTenderFileId,
                    type: 'application/pdf',
                    name: 'Load-Tender-' + job.code,
                    title: 'Load Tender',
                    url: Const.APIURI_JOBS + `/${job.id}/load_tender`
                }
            }
            this.completed = {
                jobs: res,
                skip: skip,
                count: res.length,
                show: this.completed?.show ?? false
            }
        })
    }

    onRemoveShipmentBtn(job) {
        const shipments = job.shipments.filter(it => job.setOfCheckedIds.has(it.id))
        if (!shipments.length) return
        const ids = shipments.map(it => it.warpId.toString()).join(", ")

        this.modalService.create({
            nzContent: `Are you sure you want to remove ${shipments.length} shipments: ${ids} from route ${job.code}?`,
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: true,
            nzOkText: 'Confirm',
            nzOnOk: () => {
                this.planning.removeJobShipment(job.id, [...job.setOfCheckedIds]).subscribe(res => {
                    // refresh bol
                    this.api.POST(Const.APIURI_JOBS + `/${job.id}/bol`).subscribe((x) => {
                        console.log('Refreshed BOL')
                    })
                    this.api.POST(Const.APIURI_JOBS + `/${job.id}/load_tender`).subscribe((x) => {
                        console.log('Refreshed Load Tender')
                    })
                    this.onRefreshBtn()
                })
            },
            nzCancelText: 'Cancel'
        });
    }
}